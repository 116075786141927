<template id="acitivityLogPage">
  <div class="page-main-bg">
    <v-container class="main-container custom__width">
      <v-toolbar
        height="80px"
        color="elevation-0"
        id="page-tollbar"
        class="mb-5 narrow-toolbar mt-6 tab__mb__0 tab__height__auto"
      >
        <tool-bar :breadcrumbs="breadcrumbs" subTilte="" title="سجل النشاطات" />
      </v-toolbar>
      <div
        v-if="!isPageLoading && Object.keys(activityLogs).length"
        class="logs__page"
      >
        <v-card class="my-3 py-4 px-8 mt-7">
          <div
            class="logs__content"
            v-for="(logs, date) in activityLogs"
            :key="date"
          >
            <div class="logs__header d-flex align-center pb-2">
              <div class="date__icon__div">
                <div class="date__icon">
                  <img
                    max-width="32"
                    max-height="32"
                    :src="dateIcon"
                    loading="lazy"
                  />
                </div>
                <!-- date__icon -->
              </div>
              <!-- date__icon__div -->
              <div class="logs__Date__div">
                <div class="logs__date">
                  <h4 class="font-14 mr-3 mt-2">{{ date }}</h4>
                </div>
                <!-- logs__date -->
              </div>
              <!-- logs__Date__div -->
            </div>
            <!-- logs__header -->
            <v-divider></v-divider>
            <div class="logs__body pt-3">
              <ActivityCard
                v-for="log in logs"
                :key="log._id"
                :activity-log="log"
              />
            </div>
            <!-- logs__body -->
          </div>
          <!-- logs__content -->
        </v-card>
        <!-- v-card  -->
      </div>
      <!-- logs__page -->
      <!--Loader-->
      <v-row v-if="isPageLoading">
        <v-col
          :key="i"
          class="property-col mb-2 shadow-border-radius px-0"
          cols="12"
          v-for="i in 6"
        >
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="list-item-avatar-two-line"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-sheet
        v-if="!Object.keys(activityLogs).length"
        class="pa-10 d-flex align-center justify-center flex-column bg-primary rounded-15"
        height="60vh"
      >
        <img :src="activityLogsIcon" loading="lazy" />
        <h3 class="grey--text text--darken-3 my-0">لا توجد نشاطات</h3>
      </v-sheet>
    </v-container>
    <!--Pagination-->
    <div
      class="text-center mt-5 mb-5"
      v-if="
        pagination.pagesCount > 1 &&
        !isPageLoading &&
        Object.keys(activityLogs).length
      "
    >
      <Pagination :pagination="pagination" @onChange="getActivityLogs()" />
    </div>
  </div>
  <!-- page main bg  -->
</template>
<script>
import { messageService } from '../services'
import { mapMutations } from 'vuex'
import ToolBar from '../components/listing/ToolBar'
import Pagination from '../components/helper/pagination.vue'
import ActivityCard from '../components/ActivityLogs/ActivityCard'
import dateFormat from '@/mixins/dateFormat.js'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'ActivityLog',
  mixins: [dateFormat],
  components: {
    ToolBar,
    Pagination,
    ActivityCard
  },
  data: () => {
    return {
      breadcrumbs: [
        {
          text: 'سجل النشاطات',
          disabled: true,
          to: {
            path: '/dashboard/ActivityLog'
          }
        }
      ],
      // breadcrumbs
      dateIcon: require('@/assets/img/activity-log-icons/date-activity-log.svg'),
      activityLogsIcon: require('@/assets/img/activity-log-icons/activity-log-icon.png'),
      userId: JSON.parse(localStorage.getItem('tokenData'))._id,
      pagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      activityLogs: [],
      isPageLoading: true
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    ...mapGetters('accountState', ['isCustomer'])
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    getActivityLogs() {
      const data = {
        userId: this.userId,
        pageSize: this.pagination.pageSize,
        pageNumber: this.pagination.pageNumber,
        environmentId: this.currentEnv?._id
      }
      if (this.isCustomer) {
        data.environmentId = this.currentEnv._id
      }
      messageService
        .getActivityLogs(data)
        .then((res) => {
          this.activityLogs = res.data.items
          this.activityLogs = this.activityLogs.reduce(
            (result, currentValue) => {
              const date = this.formatDate(currentValue.createdAt)
              result[date] = result[date] || []
              result[date].push(currentValue)
              return result
            },
            {}
          )
          this.pagination.pagesCount = Math.ceil(
            res.data.totalCount / this.pagination.pageSize
          )
          this.pagination.count = res.data.totalCount
        })
        .catch(() => {
          this.addNotification({
            text: 'حدث خطأ ما برجاء المحاوله مرة أخرى',
            color: 'error'
          })
        })
        .finally(() => {
          this.isPageLoading = false
        })
    }
  },
  created() {
    this.getActivityLogs()
  },
  metaInfo() {
    return {
      title: 'سجل النشاطات'
    }
  }
}
//   export
</script>
<style scoped lang="scss">
@import '@/styles/activityLog/activity-log.scss';
</style>
